import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/'
import {get} from '../http'
import metas from './meta'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: metas.global,
    children:[
      {
        path:"/",
        name:"confirm",
        component:()=>import('../views/confirm.vue')
      },
      {
        path: '/list',
        name: 'list',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/list.vue')
      }
    ]
  },
  {
    path:"/result",
    name:"result",
    meta: metas.global,
    component:()=>import('../views/result')
  },
  {
    path:"/nothing",
    name:"nothing",
    meta: metas.global,
    component:()=>import('../views/nothing')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // if(nearestWithTitle) {
  //   document.title = nearestWithTitle.meta.title;
  // }

  // // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => 
  // //   el.parentNode.removeChild(el)
  // // );

  // if (nearestWithMeta) {
  //   nearestWithMeta.meta.metaTags.map(tagDef => {
  //     const tag = document.createElement('meta');
  //     Object.keys(tagDef).forEach(key => {
  //         tag.setAttribute(key, tagDef[key]);
  //     });
  //     // tag.setAttribute('data-vue-router-controlled', '');

  //     return tag;
  //   }).forEach(tag => document.head.prepend(tag));
  // }


  if (to.path != '/nothing') {
    get('/api/wechat/getPersonalInfo').then(res=>{
      if(res.data.status.errCode === 401){
        window.location.href = '/api/wechat/oauth'
      }else if(res.data.status.errCode === 403){
        window.location.href = "/nothing"
      }else if(!res.data.data.ifEnable){
        var box = document.getElementById('bg')
        box.style.display = 'block'
      }
    })
  }
  
  next()
})


export default router
