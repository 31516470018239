<template>
  <div class="footer">
    <router-link class="f-item" to="/"><div >返款申请</div></router-link>
    <router-link class="f-item" to="/list"><div >返款记录</div></router-link>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
  width:100%;
  height:50px;
  display: flex;
  flex-flow:wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgb(240, 240, 240);
}
.f-item{
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #ddd;
  cursor: pointer;
}
.f-item:last-child{
  border:none
}
</style>
