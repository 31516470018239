
const metas = {
    global: {
        title: '申请返现',
        metaTags: [
            {
                name: 'description',
                content: '请点击此处申请返现',
            },
        ]
    }
};
 
export default metas