<template>
  <div id="app">
    <div id="bg" class="bg">
        <div class="box">
          很抱歉，您目前已被禁止申请返款请先去联系管理员解除禁止
          <div class="box-footer">
            知道了
          </div>
        </div>
    </div>
    <router-view/>
  </div>
</template>


<script>
export default {
  data() {
    return {
      
    }
  },
  methods:{
    close(){
      var box = document.getElementById('bg')
      box.style.display = 'none'
    }
  }
}
</script>

<style>
.bg{
    width:100%;
    height:100%;
    position: fixed;
    background: rgba(0,0,0,0.65);
    z-index: 999;
    display: none;
}
.box{
    width:80%;
    background: white;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding:20px 30px;text-align: center;
}

.box-footer{
  border-top:solid 1px #ddd;
  margin-top: 20px;
  padding:15px 0 0;
  color:rgb(56, 191, 1);
  cursor: pointer;
}
</style>
