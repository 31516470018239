import Vue from 'vue'
import axios from 'axios'


const http = axios.create()

http.interceptors.request.use(req => {
    req.headers['wili-token-strategy'] = 'cookie'
    return req
}, err => {
    console.log('request.interceptors', err)
    return Promise.reject(err)
})

http.interceptors.response.use(res => {
    if (res.data.status.errCode == 200) {
        return res
    }

    if (res.data.status.errCode == 401) {
        window.location.href = '/api/wechat/oauth'
        return;
    }

    alert(res.data.status.message)
},err=>{
    alert('服务器异常，请联系管理员')
    // console.log("错误信息",err.response)
    // const code = parseInt(err.response && err.response.status)
    // console.log("状态码",code)
    // if(code === 500){
    //     // alert("500")
    // }
    return Promise.reject(err)
})

// http.onError(error => {
//     console.log('这里：',error.response)
//     const code = parseInt(error.response && error.response.status)
//     console.log('响应码：',code)
//     // if (code === 400) {
//     //   redirect('/404')
//     // } else if (code === 500) {
//     //   redirect('/500')
//     // }
//   })
Vue.prototype.$http = http

export function get(url,params){
    return http.get(url,params)
}
export function post(url,data){
    return http.post(url,data)
}